/******************
 * HIGHLIGHT SLIDER
 ******************/
.b-highlights {
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    font-family: $font-family-proxima;

    .editmode {
        a {
            color: unset;
        }
        .link-editable-wrapper {
            display: flex;
            flex-direction: column;
            div {
                max-width: 300px;
            }
        }
    }

    .highlight-slider-wrapper {
        &.height {
            height: 875px;
            @include media-breakpoint-down(lg){
                height: 695px
            }
            @include media-breakpoint-down(md){
                height: 595px
            }
        }

        .layout-wrapper {
            .mobile {
                .highlightSlider {
                    position: relative;

                    .mobile-dots-here {
                        position: absolute;
                        top: -25px;
                        left: 50%;
                        width: 100%;
                        transform: translateX(-50%);
                        z-index: 80;

                        .dots-wrapper {
                            ul {
                                min-width: 240px;
                                text-align: center;
                                padding-left: 0;
                                display: flex;
                                justify-content: center;
                                @include media-breakpoint-down(sm) {
                                    justify-content: space-evenly;
                                }

                                li {
                                    position: relative;
                                    display: inline-block;
                                    width: 40px;
                                    margin: 0 10px;
                                    @include media-breakpoint-down(sm){
                                        margin: 0;
                                    }

                                    .circle {
                                        width: 40px;
                                        height: 40px;
                                        border-radius: 25px;
                                        background: $color-blue;;
                                        border: 4px solid white;
                                        position: absolute;
                                        left: 0 !important;
                                        top: 3px;
                                        z-index: 80;

                                        .icon {
                                            svg {
                                                width: 20px;
                                                height: 32px;
                                                position: absolute;
                                                top: 0;
                                                left: 6px;
                                                opacity: 0;
                                                fill: $color-white;
                                            }
                                        }
                                    }
                                    p {
                                        padding: 0;
                                    }
                                    &:hover {
                                        .circle {
                                            .icon {
                                                svg {
                                                    opacity: 1;
                                                }
                                            }
                                        }
                                        p {
                                            color: $color-red;
                                        }
                                        .circle {
                                            background: $color-red;
                                        }
                                        cursor: pointer;
                                    }

                                    &.item-active {
                                        p {
                                            color: $color-red;
                                        }
                                        .circle {
                                            background: $color-red;

                                            .icon {
                                                svg {
                                                    opacity: 1;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .mobile-headlines {
                        margin: auto;

                        .swiper-wrapper {
                            height: 45px;
                            width: 100%;
                            margin-top: 45px;

                            .swiper-slide {
                                text-transform: uppercase;
                                font-style: normal;
                                font-weight: 700;
                                font-size: 36px;
                                line-height: 45px;
                                color: #1c3a69;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                        }
                    }
                }
            }

            .normal {
                .highlightSlider {
                    .image {
                        height: 730px;
                        padding: 0;
                        width: calc(100% - 430px);

                        @include media-breakpoint-down(lg){
                            height: 550px;
                            width: calc(100% - 330px);
                        }
                        @include media-breakpoint-down(md) {
                            position: relative;
                            height: 350px;
                            background-size: cover !important;
                            background-position: center;
                            width: 100%;
                        }

                        img {
                            height: 100%;
                            object-fit: cover;
                        }
                    }

                    .dots-here {
                        padding-top: 150px;
                        height: 730px;
                        width: 430px;
                        @include media-breakpoint-down(lg){
                            height: 550px;
                            padding-top: 50px;
                            width: 330px;
                        }
                        background: white;
                        position: relative;
                        border-right: 4px solid $color-blue;
                        z-index: 80;

                        .list-slider-wrapper {
                            overflow: visible;
                        }

                        ul {
                            list-style: none;
                            padding-left: 0;

                            .highlight-button-wrapper {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-end;
                                text-align: right;
                                margin-top: 80px;
                                padding-right: 40px;
                                @include media-breakpoint-down(lg){
                                    padding-right: 25px;
                                }

                                .button {
                                    padding: 17.5px 12px;
                                    color: $white;

                                    &:hover {
                                        color: $red;
                                    }
                                }

                                .tour-details {
                                    &.link {
                                        padding-top: 20px;
                                        position: relative;
                                        display: inline-block;

                                        a {
                                            @include font-copy-m();
                                            text-transform: uppercase;
                                            padding-left: 20px;
                                            color: $color-blue;
                                            text-decoration: none;
                                        }

                                        .icon {
                                            svg {
                                                width: 10px;
                                                height: 15px;
                                                fill: $color-blue;

                                                &.icon-icon-link {
                                                    position: absolute;
                                                    top: 25px;
                                                    left: 0;
                                                    @include transition(left 0.1s);
                                                }

                                                &.icon-icon-mapmini {
                                                    position: relative;
                                                    top: 0;
                                                    right: -10px;
                                                    @include transition(left 0.1s);
                                                }
                                            }
                                        }

                                        &:hover {
                                            .icon {
                                                svg {
                                                    &.icon-icon-link {
                                                        left: 3px;
                                                        @include transition(left 0.1s);
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            li {
                                text-align: right;
                                position: relative;

                                .circle {
                                    width: 40px;
                                    height: 40px;
                                    border-radius: 25px;
                                    background: $color-blue;;
                                    border: 4px solid white;
                                    position: absolute;
                                    right: -35px;
                                    top: 3px;
                                    z-index: 90;

                                    @include media-breakpoint-down(lg){
                                        width: 35px;
                                        height: 35px;
                                    }

                                    .icon {
                                        svg {
                                            width: 20px;
                                            height: 32px;
                                            position: absolute;
                                            top: 0;
                                            left: 6px;
                                            opacity: 0;
                                            fill: $color-white;
                                            @include media-breakpoint-down(lg){
                                                width: 18px;
                                                height: 32px;
                                                top: -2px;
                                                left: 5px;
                                            }
                                        }
                                    }
                                }

                                p {
                                    text-transform: uppercase;
                                    @include font-h2();
                                    @include media-breakpoint-down(lg){
                                        font-size: 28px;
                                        line-height: 38px;
                                    }
                                    color: $color-blue;
                                    padding-right: 40px;
                                }

                                &.item-active {
                                    p {
                                        color: $color-red;
                                    }

                                    .circle {
                                        background: $color-red;

                                        .icon {
                                            svg {
                                                opacity: 1;
                                            }
                                        }
                                    }
                                }

                                &:hover {
                                    p {
                                        color: $color-red;
                                    }

                                    .circle {
                                        background: $color-red;
                                    }

                                    cursor: pointer;
                                }
                            }
                        }
                        &.items-total-7 {
                            padding-top: 80px;
                        }
                        &.items-total-8 {
                            padding-top: 50px;
                        }
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(md) {
        .container-fluid {
            padding: 0;
            margin: 0;
        }
    }
}