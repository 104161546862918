/**
 * Bootstrap Overrides
 */
$white: #fff;
$black: #000;
$blue: #1c3a69;
$red: #be2545;
$yellow: #ffe000;

$white-grey: #F1F1F1;
$grey-light: #E5E5E5;
$grey: #DADADA;
$grey-dark: #494949;
$grey-darker: #3C3C3B;
$grey-blue: #B2C3D5;
$orange: #F9B21F;
$ice-blue: #E0E6EE;
$blue-light: #6787AC;
$blue-darker: #133264;
$green: #4CB827;
$mint: #17BDA5;
$body-color: $grey-darker;
$blue-darkest: #1A335D;
$grey-2: #00000029;

$primary: $blue;
$secondary: $orange;

/*
  only put custom variables here,
  change values of standard bootstrap variables in bootstrap's variables.less
*/

$imagepath: "/static/src/fsr/img";
$fontpath: "/static/src/fsr/dist/fonts";
$icon-font-path: "/static/src/fsr/dist/vendor/bootstrap-sass-3.3.7/assets/fonts/bootstrap/";

/* === Colors === */
$color-white: #fff;
$color-black: #000;
$color-blue: #1c3a69;
$color-red: #be2545;
$color-yellow: #ffe000;

$color-white-grey: #F1F1F1;
$color-grey-light: #E5E5E5;
$color-grey: #DADADA;
$color-grey-dark: #494949;
$color-grey-darker: #3C3C3B;
$color-grey-blue: #B2C3D5;
$color-orange: #F9B21F;
$color-ice-blue: #E0E6EE;
$color-blue-light: #6787AC;
$color-blue-darker: #133264;
$color-green: #4CB827;
$color-mint: #17BDA5;
$text-color: $color-grey-darker;

$color-primary: $color-blue;
$color-secondary: $color-orange;


/* === Fonts === */
$font-family-base: 'Source Sans Pro', sans-serif;
$font-family-base-light: 'Helvetica LT W01 Light', sans-serif;
$font-family-base-bold: 'Helvetica LT W01 Bold', sans-serif;
$fontfamily-fontawesome-brands: 'Font Awesome 5 Brands';
$font-family-proxima: proxima-nova, sans-serif;

$font-size-24px: 1.5rem;
$font-size-14px: 0.875rem;
$font-size-20px: 1.25rem;

// font weight is beeing controlled via font-family
$font-weight-light: normal;
$font-weight-regular: normal;
$font-weight-bold: normal;

/* === Heights === */
$header-height: 95px;

/* === Borders === */


/* === Buttons === */


/* === Panels === */


/* === Sizes === */
$screen-xs-max: 575px;
$screen-sm-min: $screen-xs-max + 1;
$screen-sm-max: 767px;
$screen-md-min: $screen-sm-max + 1;
$screen-md-max: 991px;

$default-space: 15px;

$danger: red;
$warning: yellow;
$success: green;

/* === Interferer === */
$border-width:3px;
$interferer-background: $red;
$interferer-button: $blue;