@import "bootstrap";
$name: '.footer';

#{$name} {
    &__top {
        font-family: $font-family-base !important;
        #{$name}__logo-list--headline > h4 {
            font-style: normal;
            font-weight: 700;
            font-size: 36px;
            line-height: 45px;
        }
    }
    &__main {
        #{$name}__column-headline-link, #{$name}__column-headline {
            margin: 0;
            padding-top: 0;
            padding-bottom: 15px;
            font-family: $font-family-base;
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 24px;
            color: #fff;
            text-decoration: none;
        }
        #{$name}__column-link {
            font-family: $font-family-base;
            font-style: normal;
            font-weight: 100;
            font-size: 16px;
            line-height: 22px;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
        #{$name}__column-four {
            p {
                font-family: $font-family-base;
                font-style: normal;
                font-weight: 100;
                font-size: 16px;
                line-height: 22px;
                text-decoration: none;
                > a {
                    text-decoration: none;
                    font-size: 14px;
                }
            }
        }
    }
    &__bottom {
        &-legal-link {
            font-family: $font-family-base;
            font-style: normal;
            font-weight: 100;
            font-size: 16px;
            line-height: 22px;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
        &-copyright {
                font-family: $font-family-base;
                font-style: normal;
                font-weight: 100;
                font-size: 16px;
                line-height: 22px;
                text-decoration: none;
        }
    }
}