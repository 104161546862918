.b-my-like {
    margin: 20px 0;
    &__wrapper {
        .b-my-like__copy {
            @include font-copy;
        }
    }
    .cookieconsent-optout-marketing {
        a.btn {
            color: $white;
            background-color: $red;
            border: $white 1px solid;
            border-radius: 8px;
            padding: 5px 10px;
            font-size: 24px;

            @include media-breakpoint-down(xxl) {
                font-size: 20px;
            }

            @include media-breakpoint-down(lg) {
                font-size: 16px;
            }

            &:hover {
                border: none;
                padding: 6px 11px;
                background-color: $white;
                color: $black;
            }
        }
    }
}