/* === Font Mixins === */

@mixin font-copy-m {
    font-family: $font-family-proxima;
    font-style: normal;
    font-weight: 100;
    font-size:16px;
    line-height: 22px;
}
@mixin font-copy-l {
    font-family: $font-family-proxima;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
}
@mixin font-copy-xxl {
    font-family: $font-family-proxima;
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 80px;
}
@mixin font-h1 {
    font-family: $font-family-proxima;
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 45px;
    @media (max-width: $screen-sm-max) {
        font-size: 30px;
        line-height: 35px;
        margin-bottom: 25px;
    }
}
@mixin font-h2 {
    font-family: $font-family-proxima;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 45px;
}
@mixin font-h3 {
    font-family: $font-family-proxima;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 23px;
}
@mixin font-copy-s {
    font-family: roboto-slab, serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}
@mixin font-copy-xl {
    font-family: roboto-slab, serif;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 27px;
}
@mixin font-copy-xs {
    font-family: roboto-slab, serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
}


/* === Text Mixins === */

@mixin text-uppercase {
    text-transform: uppercase;
}

@mixin font-copy {
    font-size: $font-size-base;
    line-height: $line-height-base;
    color: $color-black;
    font-family: $font-family-base;
    font-weight: $font-weight-regular;
}

// Button sizes
@mixin button-size($padding-vertical, $padding-horizontal, $font-size, $line-height, $border-radius) {
    padding: $padding-vertical $padding-horizontal $padding-vertical;
    font-size: $font-size;
    line-height: $line-height;
    border-radius: $border-radius;
}

// quadratic images
@mixin quadratic-image($max-size: 100%) {
    padding-top: 100%;
    position: relative;

    img {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        max-width: $max-size;
        max-height: $max-size;
    }
}

// transforms
@mixin transform($transform) {
    -webkit-transform: $transform;
    -ms-transform: $transform; // IE9 only
    -o-transform: $transform;
    transform: $transform;
}

@mixin translate($x, $y) {
    -webkit-transform: translate($x, $y);
    -moz-transform: translate($x, $y);
    -ms-transform: translate($x, $y);
    -o-transform: translate($x, $y);
    transform: translate($x, $y);
}

@mixin translate-rotate($x, $y, $degrees) {
    -webkit-transform: translate($x, $y) rotate($degrees);
    -ms-transform: translate($x, $y) rotate($degrees); // IE9 only
    -o-transform: translate($x, $y) rotate($degrees);
    transform: translate($x, $y) rotate($degrees);
}

// browser support flexbox
@mixin flex-parent($flex-flow: row wrap, $align-items: stretch, $justify-content: space-between) {
    display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;      /* TWEENER - IE 10 */
    display: -webkit-flex;     /* NEW - Chrome */
    display: flex;             /* NEW, Spec - Opera 12.1, Firefox 20+ */

    -webkit-flex-flow: $flex-flow;
    -moz-flex-flow: $flex-flow;
    -ms-flex-flow: $flex-flow;
    flex-flow: $flex-flow;

    -webkit-align-items: $align-items;
    -moz-align-items: $align-items;
    -ms-align-items: $align-items;
    align-items: $align-items;

    -webkit-justify-content: $justify-content;
    -moz-justify-content: $justify-content;
    -ms-justify-content: $justify-content;
    justify-content: $justify-content;
}

@mixin flex-child() {

}

// font sizes flow mixin
@mixin flow-font-size($flow-variable, $flow-index: auto){
    $flow-length: length($flow-variable);

    @if $flow-index == "auto" {
        @include flow-font-size($flow-variable, 1);
        @media (min-width: $screen-sm-min) { @include flow-font-size($flow-variable, 2); }
        @media (min-width: $screen-md-min) { @include flow-font-size($flow-variable, 3); }
        @media (min-width: $screen-lg-min) { @include flow-font-size($flow-variable, 4); }
    } @else if $flow-index >= $flow-length {
        font-size: nth($flow-variable, $flow-length);
    } @else {
        font-size: nth($flow-variable, $flow-index);
    }
}

// Icon
@mixin icon-fill($fill: #ffffff) {
    fill: $fill;
}

@mixin icon($size, $fill: null) {
    @if $size == tiny {
        width: 10px;
        height: 10px;
    } @else if $size == small {
        width: 16px;
        height: 16px;
    } @else {
        width: $size;
        height: $size;
    }
    @if $fill {
        @include icon-fill($fill);
    }
}