@import "bricks/accordion";
@import "bricks/highlight";
@import "bricks/text-media";
@import "bricks/html";
@import "bricks/my-like";

.b-column-configurator {
    margin: 50px 0;
    .container {
        max-width: 1920px;
        margin: 0 auto;
        padding: 0;
    }
}