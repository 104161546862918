/*-------------------------------------------------------------------------*/
/*	typography */
/*-------------------------------------------------------------------------*/

body {
    font-size: $font-size-base;
    line-height: $line-height-base;
}

html {

}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @include font-copy();
    @include font-copy-s();
    .fullPage {
        max-width: 1920px;
        margin: 0 auto;
        box-shadow: 0 2px 20px 10px rgba(0, 0, 0, 0.15);
        overflow: hidden;
    }

}

.text-container {
    padding-top: 158px;
    @media (max-width: 650px) {
        padding-top: 0;
    }
}

/******************
 * HEADER
 ******************/
header {
    max-width: 1920px;
    margin: 0 auto;

    .burgerWrapper {
        position: relative;
        display: inline-block;
        width: 30px;
        height: 20px;
        margin-left: 5px;
        margin-bottom: 10px;
        top: 10px;
        .burgerRow {
            position: relative;
            background: #FFFFFF;
            width: 30px;
            height: 2px;
            -webkit-transition: all 0.6s ease-in-out;
            -o-transition: all 0.6s ease-in-out;
            transition: all 0.6s ease-in-out;
            &.top {
                top: 2px;
            }
            &.middle {
                top: 8px;
            }
            &.bottom {
                top: 14px;
            }
        }
        &.open {
            .burgerRow {
                &.top {
                    top: 10px;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    -o-transform: rotate(45deg);
                    transform: rotate(45deg);
                }
                &.middle {
                    opacity: 0;
                }
                &.bottom {
                    top: 6px;
                    -webkit-transform: rotate(-45deg);
                    -ms-transform: rotate(-45deg);
                    -o-transform: rotate(-45deg);
                    transform: rotate(-45deg);
                }
            }
        }
    }

    &.container-fluid {
        position: relative;
        padding: 0;
    }
    .bus-wrapper {
        height: 755px;
        position: absolute;
        top: 95px;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 999;
        .bus {
            height: 360px;
            width: 520px;
            bottom: -25px;
            position: absolute;
            z-index: 11;
            background: url("../../img/ani-bus-schraeg-links.png") no-repeat;
            background-size: 100%;
            transform: translate(-520px, 20px);
            @include transition(transform 3s);
            &.animation {
                transform: translate(0, 0);
                @include transition(transform 3s);
            }
        }
    }
    .bus-video-header {
        bottom: -55px;
        @media (max-width: 1440px) {
            bottom: -32px;
        }
        @media (max-width: 1200px) {
            bottom: -18px;
        }
        position: absolute;
        z-index: 11;
        #bus-video-header_buffer {
            display: none;
        }
        #bus-video-header_output {
            display: block;
            @media (max-width: 1440px) {
                height: 400px;
            }
            @media (max-width: 1200px) {
                height: 350px;
            }
        }
    }

    .navigation {
        position: fixed;
        left: 0;
        top: $header-height;
        background: $color-blue;
        width: 220px;
        z-index: 15;
        padding-bottom: 15px;
        padding-top: 10px;
        transform: translateY(-500px);
        @include transition(transform 0.7s);
        &.nice {
            &.open {
                transform: translateY(0px) !important;
            }
        }
        &.open {
            transform: translateY(0) !important;
            @include transition(transform 0.7s);
        }
        ul {
            list-style: none;
            padding-left: 20px;
            margin-bottom: 0;
            color: $color-white;
            li {
                padding-bottom: 40px;
                a {
                    color: $color-white;
                    font-weight: lighter;
                    font-size: 15px;
                    @include font-h3();
                }
                ul {
                    list-style: none;
                    margin-top: 5px;
                    li {
                        text-transform: none;
                        padding-bottom: 0;
                        //height: 24px;
                        position: relative;
                        span {
                            position: absolute;
                            top: 0;
                            left: -18px;
                            @include transition(left 0.1s);
                            .icon-icon-link {
                                width: 10px;
                                height: 10px;
                                fill: $color-white;
                            }
                        }
                        &:hover {
                            span {
                                left: -15px;
                                @include transition(left 0.1s);
                            }
                        }
                        a {
                            @include font-copy-m();
                            text-transform: uppercase;
                        }
                    }
                }
            }
        }
    }
}

.headerImage-wrapper {
    margin: 95px auto 0 auto;
    position: relative;
    max-width: 1920px;
    overflow-x: clip;

    &.hasDiagonalElement {
        margin-bottom: 110px;
        @include media-breakpoint-down(sm) {
            margin-bottom: 0;
        }
    }


    @include media-breakpoint-down(xl) {
        margin-top: 80px;
    }
    @include media-breakpoint-down(lg) {
        margin-top: 70px;
    }
    @include media-breakpoint-down(sm) {
        margin-top: 50px;
    }

    &.height {
        height: 1000px;
        @include media-breakpoint-down(md) {
            height: 700px;
        }
        @include media-breakpoint-down(sm) {
            height: fit-content;
        }
    }
    &.not-home {
        height: 100%;
        max-height: 755px;

        img {
            max-height: 805px;
        }
    }

    .headerImage {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        @include media-breakpoint-down(sm) {
            height: 630px;
        }

        &.not-home {
            height: 100%;
            max-height: 755px;
        }
    }
    .inner {
        width: 100%;
        height: 100%;
        &.home {
            .text-block {
                top: 50%;
                .header-flags {
                    margin-bottom: 25px;
                }
                .responsive-image {
                    max-width: 100%;
                    height: auto;
                }
            }

            @include media-breakpoint-down(sm) {
                height: 630px;
            }
        }
        .text-block {
            margin: auto;
            position: absolute;
            top: 50%;
            @include translate(0, -50%);
            left: 0;
            bottom: 0;
            right: 0;
            text-align: center;
            h1, p {
                color: $color-white;
                text-shadow: 3px 3px 20px rgba(0, 0, 0, 0.4);
            }

            h1 {
                font-size: 60px;
                line-height: 75px;

                @include media-breakpoint-down(md) {
                    font-size: 30px;
                    line-height: 50px;
                }
            }

            p {
                font-family: $font-family-proxima;
                font-size: 19px;

                @include media-breakpoint-down(md) {
                    font-size: 12px;
                }
            }

            .btn {
                margin-top: 35px;
                width: 300px;
                font-family: $font-family-proxima;
                font-style: normal;
                font-weight: 500;
                font-size: 24px;
                line-height: 23px;
                color: $white;

                .details {
                    margin-top: 5px;
                    font-family: roboto-slab,serif;
                    font-style: normal;
                    font-weight: 300;
                    font-size: 14px;
                    text-transform: none;
                    color: $white;
                    display: block;
                }
            }
            .btn:hover {
                color: $black;
            }

        }
        &.default {
            .button {
                width: 300px;
                h3 {
                    margin: 0;
                }
                .details {
                    @include font-copy-xs();
                    text-transform: none;
                    color: $color-white;
                }
            }
        }
    }
    &.not-home {
        height: 100%;
        max-height: 755px;
        .headerImage {
            position: relative;
            left: auto;
            top: 0;
            -webkit-transform: none;
            -ms-transform: none; // IE9 only
            -o-transform: none;
            transform: none;
        }
    }
    @media (max-width: $screen-md-max) {
        &.not-home {
            height: 100%;
            .headerImage {
                width: 150%;
                left: 50%;
                top: 0;
                -webkit-transform: translate(-50%);
                -ms-transform: translate(-50%); // IE9 only
                -o-transform: translate(-50%);
                transform: translate(-50%);
            }
            .inner {
                .text-block {
                    bottom: auto;
                    h1 {
                        margin-top: 0;
                    }
                }
            }
        }
    }
}
.diagonal {
    height: 180px;
    margin: -45px 0 -70px 0;
    z-index: 10;
    position: relative;
    p {
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 11;
        display: inherit;
        @include translate(-50%, -50%);
        vertical-align: middle;
        text-transform: uppercase;
        @include font-copy-l();
        strong {
            @include font-h2();
        }
    }
    &.d-blue {
        background: url("../../img/diagonal-element-blue.png") no-repeat;
        background-size: cover;
        p {
            color: $color-white;
        }
    }
    &.d-white {
        background: url("../../img/diagonal-element-weiss.png") no-repeat;
        background-size: cover;
        p {
            color: $color-blue;
        }
    }
}

/******************
 * DiscoveringTours-wrapper
 ******************/
.discoveringTours-wrapper {
    min-height: 960px;
    position: relative;
    //padding-top: 85px;
    padding-top: 150px;
    @media(max-width: 991px) {
        min-height: 1080px;
    }
    @media(max-width: 650px) {
        padding-top: 50px;
        min-height: 960px;
    }
    @media(max-width: 560px) {
        min-height: 1000px;
    }
    @media(max-width: 426px) {
        min-height: 1040px;
    }
    color: $color-white;
    overflow: hidden;
    h2 {
        position: relative;
        text-align: center;
        letter-spacing: 2px;
        margin: 0;
    }
    .bus-small-anker {
        position: absolute;
        left: 0;
        top: 100px;
    }
    .bus-video-snippet,
    .bus-video-snippet-mobile {
        bottom: 0;
        position: absolute;
        z-index: 11;
        @media(max-width: 1320px) {
            width: 310px;
        }
        #bus-video-snippet_buffer,
        #bus-video-snippet_buffer_mobile {
            display: none;
        }
        #bus-video-snippet_output,
        #bus-video-snippet_output_mobile {
            display: block;
            transform: translate(-530px, 0);
            @include transition(transform 3.2s ease-out);
            &.animation {
                transform: translate(0, 0);
                @include transition(transform 3.2s ease-out);
                @media(max-width: 1550px) {
                    transform: translate(-100px, 0);
                }
                @media(max-width: 1320px) {
                    transform: translate(-180px, 0);
                }
                @media(max-width: 1200px) {
                    transform: translate(-220px, 0);
                }
                @media(max-width: 1100px) {
                    transform: translate(-260px, 0);
                }
                @media(max-width: 990px) {
                    transform: translate(0, 0);
                }
            }
        }
    }

    .bus-small {
        height: 201px;
        width: 520px;
        bottom: 0;
        position: absolute;
        z-index: 11;
        background: url("../../img/ani-bus seite.png") no-repeat;
        background-size: 100%;
        transform: translate(-530px, 0);
        @include transition(transform 3s);
        &.animation {
            transform: translate(-100px, 0);
            @include transition(transform 3s);
        }
        @media (min-width: $screen-sm-max) and (max-width: 1099px) {
            height: 100px;
            width: 260px;
            transform: translate(-260px, -10px);
        }
        @media (min-width: 1100px) and (max-width: 1270px) {
            height: 130px;
            width: 330px;
            transform: translate(-330px, -10px);
        }
        @media (min-width: 1270px) and (max-width: 1488px) {
            height: 161px;
            width: 420px;
            transform: translate(-420px, -10px);
        }
    }
    .subtitle {
        position: relative;
        text-align: center;
        margin: 0;
        @include font-copy-xl();
    }
    .background-image {
        position: absolute;
        top: 0;
        left: 50%;
        @include translate(-50%, 0);
    }
    .box-wrapper {
        padding-top: 50px;
        max-height: 750px;
        @include media-breakpoint-down(md) {
            .box {
                max-width: 300px;
                .inner {
                    .button {
                        max-width: 300px !important;
                        width: 85% !important;
                    }
                }
                &.big-box {
                    border: 0 !important;
                    .headline {
                        font-size: 32px !important;
                    }
                    .detail-wrapper {
                        .disturber {
                            top: 135px;
                        }
                    }
                }
                .detail-wrapper {
                    padding: 35px 0 0 17px;
                    .disturber {
                        top: 110px;
                        right: -25px;
                    }
                    .tour-details {
                        &.person {
                            padding-top: 0;
                        }
                        &.link {
                            p {
                                padding-left: 25px;
                            }
                        }
                    }
                }
            }
            .small-box {
                .headline {
                    font-size: 32px;
                }
            }
        }
        @include media-breakpoint-down(sm) {
            .box {
                .detail-wrapper {
                    .disturber {
                        top: 65px;
                        right: -35px;
                        &.middle {
                            top: 37px;
                            right: -37px;
                        }
                    }
                }
            }
        }
    }
    .bottom-detail {
        position: relative;
        text-align: center;
        padding: 50px 0;
        h3 {
            margin: 0;
            text-transform: uppercase;
        }
        .tour-details {
            &.link {
                margin-top: 20px;
                position: relative;
                display: inline-block;
                a {
                    @include font-copy-m();
                    text-transform: uppercase;
                    padding-left: 20px;
                    &:hover {
                        color: $color-white;
                    }
                    text-decoration: none;
                }
                .icon {
                    svg {
                        width: 10px;
                        height: 15px;
                        fill: $color-white;
                        &.icon-icon-link {
                            position: absolute;
                            top: 4px;
                            left: 0;
                            @include transition(left 0.1s);
                        }
                        &.icon-icon-mapmini {
                            position: relative;
                            top: 0;
                            right: -10px;
                            @include transition(left 0.1s);
                        }
                    }
                }
                &:hover {
                    .icon {
                        svg {
                            &.icon-icon-link {
                                left: 3px;
                                @include transition(left 0.1s);
                            }
                        }
                    }
                }
            }
        }
        @include media-breakpoint-down(lg){
            padding: 37px 0;
        }
    }
    .box {
        text-align: center;
        position: relative;
        max-width: 390px;
        padding: 0 15px;
        .background {
            position: absolute;
            top: 0;
            right: 20px;
            bottom: 0;
            left: 20px;
            opacity: 0.5;
            z-index: 5;
            background-color: rgba(255, 255, 255, 0.7);
        }
        .inner {
            position: relative;
            z-index: 8;
        }
        .price {
            text-shadow: -2px 0 $color-red, 0 2px $color-red, 2px 0 $color-red, 0 -2px $color-red;
            margin: 0;
            height: 80px;
        }
        .detail-wrapper {
            padding: 40px 0 0 40px;
            .tour-details {
                text-align: left;
                @include font-copy-xl();
                .icon {
                    position: relative;
                    display: block;
                    svg {
                        width: 20px;
                        height: 20px;
                        fill: $color-white;
                        position: absolute;
                        top: 3px;
                        left: 0;
                    }
                }
                p {
                    padding-left: 30px;
                }
                &.person {
                    padding-top: 20px;
                    height: 70px;
                }
                &.link {
                    padding-top: 20px;
                    p {
                        a {
                            @include font-copy-m();
                            text-transform: uppercase;
                            &:hover {
                                color: $color-white;
                            }
                            text-decoration: none;
                        }
                    }
                    .icon {
                        svg {
                            width: 10px;
                            height: 25px;
                            left: 5px;
                            @include transition(left 0.1s);
                        }
                    }
                    &:hover {
                        .icon {
                            svg {
                                left: 8px;
                                @include transition(left 0.1s);
                            }
                        }
                    }
                }
            }
        }
        .button {
            width: 250px;
            height: 60px;
            padding-top: 18px;
            position: relative;
            color: $white;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            text-transform: uppercase;
            @include media-breakpoint-down(lg){
                width: 250px;
            }
        }
        .button:hover {
            color: $red;
        }
        .disturber {
            width: 100px;
            height: 100px;
            position: absolute;
            top: 80px;
            right: 5px;
            img {
                width: 100%;
            }
        }
        .subtitle {
            @include font-copy-m();
            text-transform: uppercase;
            height: 50px;
            p {
                margin: 0;
            }
        }
    }
    .small-box {

        .headline {
            @include font-h2();
            margin-top: 25px;
        }
        .price {
            @include font-h2();
            padding-top: 28px;
            letter-spacing: 5px;
        }
    }
    .big-box {
        border-left: 1px solid $color-white;
        border-right: 1px solid $color-white;
        .headline {
            @include font-copy-xxl();
            margin: 0;
            line-height: 60px;
            @include media-breakpoint-down(lg){
                font-size: 55px;
            }
        }
        .price {
            @include font-copy-xxl();
            letter-spacing: 5px;
        }
        .disturber {
            top: 105px;
        }
        .button {
            top: 1px;
            position: relative;
        }
    }

    .container  {
        @include media-breakpoint-down(md) {
            width: 100% !important;
        }

        @include media-breakpoint-down(lg) {
            margin-left: 0;
            margin-right: 0;
            padding-left: 0;
            padding-right: 0;
        }
    }
}

@media (min-width: $screen-sm-max) and (max-width: 1025px) {
    .touch {
        .discoveringTours-wrapper {
            .box {
                .disturber {
                    right: -15px !important;
                }
            }
            .big-box {
                .headline {
                    font-size: 55px !important;
                }
            }
        }
    }
}

/******************
 * Promise-wrapper
 ******************/
.promise-wrapper {
    position: relative;
    overflow: hidden;
    max-width: 1920px;
    margin: 0 auto;
    .bus-right-anker {
        position: absolute;
        left: 0;
        top: -100px;
    }
    .container-fluid {
        padding: 80px 15px 70px 15px;
        @media (max-width: $screen-xs-max) {
            padding: 25px 15px 70px 15px;
        }
        .box-wrapper {
            padding: 30px 15px;
            @include media-breakpoint-down(lg) {
                padding: 0 15px;
            }
        }
        .box {
            position: relative;
            min-height: 90px;
            margin-left: 85px;
            @media (max-width: $screen-xs-max) {
                margin-left: 65px;
            }
            .number {
                background: $color-blue;
                color: $color-white;
                border-radius: 20px;
                display: inline-block;
                padding: 3px;
                width: 30px;
                text-align: center;
                height: 30px;
                @include font-copy-l();
                position: absolute;
                left: -50px;
                margin-top: -3px;
            }
            h3 {
                @include font-h3();
                color: $color-blue;
                margin-bottom: 3px;
            }
            p {
                @include font-copy-s();
                color: $color-blue;
            }
        }
        .link {
            height: 30px;
            position: relative;
            margin: 0 auto;
            width: 340px;
            padding-left: 10px;
            margin-top: 30px;
            @include font-copy-m();
            .icon {
                svg {
                    width: 10px;
                    height: 15px;
                    fill: $color-blue;
                    position: absolute;
                    left: -10px;
                    top: 4px;
                }
            }
            p {
                a {
                    text-decoration: none;
                    text-transform: uppercase;
                    color: $color-blue;
                }
                &:hover {
                    .icon {
                        svg {
                            left: -7px;
                            @include transition(left 0.1s);
                        }
                    }
                }
            }
        }
        .slope {
            background-color: $blue;
            width: 110px;
            position: absolute;
            left: -1px;
            top: 144px;
            bottom: 0;
        }
    }
    .bus-right-wrapper {
        height: 300px;
        width: 300px;
        position: absolute;
        right: 0;
        bottom: 0;
        top: 53%;
        transform: translateY(-50%);
        .bus-right {
            height: 320px;
            width: 300px;
            bottom: -45px;
            position: absolute;
            z-index: 11;
            //background: url("/bus-bilder/stadtrundfahrt-bus-rechts.png") no-repeat;
            background-repeat: no-repeat;
            background-size: 100%;
            transform: translate(300px, 0);
            @include transition(transform 3s);
            &.animation {
                transform: translate(0, 0);
                @include transition(transform 3s);
            }
        }
    }
}

/******************
 * FOOTER
 ******************/
footer {
    background: $color-blue;
    color: white;
    .footer-inner {
        .footerNavigation {
            .textWrapper {
                h2 {
                    text-transform: uppercase;
                    text-align: center;
                    padding: 60px 0;
                }
            }
            a {
                color: $color-white;
                font-weight: 100;
            }
            ul {
                padding: 0;
                list-style: none;
                h5 {
                    margin: 0;
                    padding-top: 0;
                    padding-bottom: 15px;
                    @include font-copy-l();
                }
                li {
                    a {
                        @include font-copy-m();
                    }
                }
                p {
                    @include font-copy-m();
                }
            }
        }
    }
}

.siteOverlay {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 9997;
}


#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection {
    background-color: #cdcdcd!important;
    border-color: #cdcdcd!important;
}

.default-button {
    background: $color-red;
    border: 1px solid $color-white;
    border-radius: 15px;
    width: 240px;
    padding: 8px 12px;
    position: relative;
    @include transition(background 0.4s);
    h3 {
        margin: 0;
        text-transform: uppercase;
        color: $color-white;
        @include font-h3();
    }
    &:hover {
        background: $color-white;
        border: 1px solid $color-red;
        cursor: pointer;
        @include transition(background 0.4s);
        h3, .details {
            color: $color-red !important;
        }
    }
    a {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        text-transform: uppercase;
    }

    &--blue {
        background: $color-blue;
        @include font-h3();
        font-size: 20px;
        font-weight: 600;
        padding: 10px 12px;

        &:hover {
            border: 1px solid $color-blue;
            color: $color-blue;

            h3, .details {
                color: $color-blue !important;
            }
        }
    }
}

.booking-btn {
    @include font-h3();
}

a {
    color: $color-white;
    @include font-copy-m();
    font-weight: 400;
    &:hover {
        cursor: pointer;
    }
}

h1 {
    @include font-h1();
}

h2 {
    @include font-h2();
    &.bigHeadline {
        background: $color-blue;
        padding: 50px 0;
        margin: 0;
        text-align: center;
        color: $color-white;
        @include font-h2();
    }
}

h3 {
    @include font-h3();
}

.uppercase {
    text-transform: uppercase;
}

.divider {
    width: 100%;
    height: 4px;
    transform: rotate(-2deg);
    margin-bottom: 70px;
    background: $color-blue;
}

.diagonal {
    height: 180px;
    //margin: -45px 0 -70px 0;
    z-index: 10;
    position: relative;
    p {
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 11;
        display: inherit;
        @include translate(-50%, -50%);
        vertical-align: middle;
        text-transform: uppercase;
        @include font-copy-l();
        strong {
            @include font-h2();
        }
    }
    &.d-blue {
        background: url("../../img/diagonal-element-blue.png") no-repeat;
        background-size: cover;
        p {
            color: $color-white;
        }
    }
    &.d-white {
        background: url("../../img/diagonal-element-weiss.png") no-repeat;
        background-size: cover;
        p {
            color: $color-blue;
        }
    }
}

.diagonal-header-rotate {
    height: 170px;
    position: absolute;
    bottom: -110px;
    z-index: 10;
    left: -15px;
    right: -15px;
    background: #1c3a69;
    color: white;
    &.d-white {
        background: #FFFFFF;
        color: $color-blue;
    }
    &.d-red {
        background: $color-red;
        color: #ffffff;
        text-align: center;
    }

    -webkit-backface-visibility: hidden;
    outline: 1px solid transparent;
    @include font-copy-l();
    font-weight: $font-weight-regular;

    strong {
        @include font-h2();
    }
    @include media-breakpoint-down(sm){
        height: auto;
        left: 0;
        right: 0;
        position: static;
        transform: none;
        bottom: unset;
    }
    .wrapper {
        text-transform: uppercase;
        position: absolute;
        top: 45%;
        left: 50%;
        width: 50%;
        @media (max-width: 1420px) {
            width: 60%;
        }
        @media (max-width: 1290px) {
            width: 70%;
        }
        @media (max-width: 1010px) {
            width: 80%;
        }
        @media (max-width: 882px) {
            width: 90%;
        }
        @media (max-width: 782px) {
            font-size: 16px;
            strong {
                font-size: 22px;
                line-height: 28px;
            }
        }
        transform-origin: left;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        @include media-breakpoint-down(sm) {
            height: auto;
            left: 0;
            right: 0;
            width: 100%;
            padding: 15px;
            position: static;
            transform: none;
            bottom: unset;
        }
        p {
            margin: 0;
        }

        &.has-link {
            padding-bottom: 25px;

            a.btn {
                color: white;

                @media (min-width: 651px) {
                    position: absolute;
                    bottom: -25px;
                    left: 50%;
                    transform: translateX(-50%);
                }

                @media (max-width: 650px) {
                    margin-top: 10px;
                    font-size: 17px;
                    width: auto;
                    padding-left: 15px;
                    padding-right: 15px;
                }
            }

            a.btn:hover {
                color: $blue;
            }

            @media (max-width: $screen-md-max) and (min-width: 783px) {
                strong {
                    line-height: 36px;
                }
            }
        }
    }
}

.language-switch-normal {
    .language-switch-wrapper {
        position: absolute;
        top: 30px;
        left: 80px;
    }
}

.language-switch-menu {
    position: absolute;
    right: 20px;
    .language-switch-wrapper {
        position: static;
        top: 0;
        left: 0;
    }
}

.language-switch-wrapper {
    a, span {
        @include font-h3();
        color: #FFF;
    }
    a:hover,
    a:active,
    a:visited {
        color: #FFF;
        text-decoration: none;
    }
    a {
        span {
            font-weight: 200;
        }
        &.active {
            span {
                font-weight: 500;
            }
        }
    }
}

.language-switch-footer {
    margin-bottom: 15px;
    .language-switch-wrapper {
        a, span {
            padding-top: 0;
            padding-bottom: 15px;
            font-family: $font-family-proxima;
            font-style: normal;
            font-weight: 100;
            font-size: 18px;
            line-height: 24px;
            color: #FFF;
            &.active {
                font-weight: 700;
            }
        }
    }
}

#siteWrapper {
    .editmode {
        font-family: Helvetica,sans-serif;
        font-size: 14px;
        line-height: 1.42857;
        color: #3c3c3b;
        .card-body {
            min-height: 20px;
            padding: 19px;
            margin-bottom: 20px;
            background-color: #f5f5f5;
            border: 1px solid #e3e3e3;
            border-radius: 4px;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.05);

            li.list-group-item {
                background-color: #f5f5f5;
                border: none;
            }
        }
        label {
            display: inline-block;
            max-width: 100%;
            margin-bottom: 5px;
            font-weight: 700;
        }
    }
}