.b-text-media {
    max-width: 1920px;
    margin: 0 auto 50px;

    .editmode {
        color: $black;
    }

    .row {
        max-width: 1920px;
    }

    .text-image-wrapper {
        position: relative;
        color: $color-blue;

        .layout-wrapper {
            position: relative;
            min-height: 500px;

            .slope {
                background-color: $blue;
                width: 110px;
                position: absolute;
                left: 0;
                top: -1px;
                bottom: 0;
            }

            .text-box {
                padding-bottom: 80px;
                padding-top: 80px;

                @include media-breakpoint-up(md) {
                    padding-left: 150px;
                }
                @include media-breakpoint-down(md) {
                    padding: 40px !important;
                }

                h3 {
                    @include font-h3();
                    color: $color-blue;
                }
                p {
                    color: $color-blue;
                    @include font-copy-s();

                    a {
                        margin-left: 2px;
                        color: $color-blue;
                        text-decoration: none;

                        svg {
                            height: 12px;
                            position: relative;
                            left: -1px;
                            width: 20px;
                            fill: $color-blue;
                            @include transition(left 0.1s);
                        }

                        &:hover {
                            text-decoration: underline;
                            svg {
                                left: 1px;
                                @include transition(left 0.1s);
                            }
                        }
                    }
                }

                .button {
                    margin: 20px 0;
                    padding: 17.5px 12px;
                    color: $white;

                    &:hover {
                        color: $red;
                    }
                }
            }


            .image {
                position: absolute;
                right: 0;
                top: 0;
                width: 58%;
                bottom: 0;

                @include media-breakpoint-down(xxl) {
                    width: 50%;
                }
                @include media-breakpoint-down(xl) {
                    width: 40%;
                }
                @include media-breakpoint-down(md) {
                    position: relative !important;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    width: 100% !important;
                    height: 300px;
                }

                a {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    background-size: cover;
                    background-position: center;

                    @media screen and (min-width: 1920px){
                        background-position: bottom 0 right -20px;
                    }
                    @include media-breakpoint-down(md) {
                        background-repeat: no-repeat;
                        background-position: center;
                    }

                    .icon {
                        position: absolute;
                        bottom: 0;
                        svg {
                            width: 100px;
                            height: 100px;
                            @include media-breakpoint-down(xxl) {
                                width: 75px;
                                height: 75px;
                            }
                            @include media-breakpoint-down(lg) {
                                width: 50px;
                                height: 50px;
                            }
                        }
                    }
                }
            }
        }
    }
}


